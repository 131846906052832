$(document).ready(function () {
  initializeMenuDropdown();
  initializeMenuNavToggle();
  initializeStickyNavBar();
});

const globalAnimationDuration = 250; // Make sure this value is equivalent to the css animation duration.
let state = {
  navDropDownIsOpen: false,
  isAnimating: false,
  navBarIsOpen: false,
  navBarIsSticky: false
};

// Helper functions
const handleAnimation = function (config) {
  if (state[config.stateKey]) {
    setTimeout(function () {
      config.element.toggleClass(config.classNameDisplay);
      state[config.stateKey] = false;
    }, config.duration);
  } else {
    config.element.toggleClass(config.classNameDisplay);
    state[config.stateKey] = true;
  }
  setTimeout(function () {
    config.element.toggleClass(config.classNameAnimation);
    config.generalAnimation();
  });
};

const startAnimation = function (config) {
  if (!state.isAnimating) {
    state.isAnimating = true;
    handleAnimation(config);
    setTimeout(function () {
      state.isAnimating = false;
    }, config.duration);
  } else {
  }
};

const initializeMenuDropdown = function () {
  const navBarItemToggleSelector = $('.nav-bar__item__toggle');
  const id = navBarItemToggleSelector.attr('id');
  const dropDownSelector = $('#' + 'drop-down' + id);
  const animateNavBarItemToggle = function () {
    navBarItemToggleSelector.toggleClass('nav-bar__item__toggle--closed');
  };
  const animationConfig = {
    element: dropDownSelector,
    stateKey: 'navDropDownIsOpen',
    duration: globalAnimationDuration,
    classNameAnimation: 'nav-drop-down--closed',
    classNameDisplay: 'nav-drop-down--hidden',
    generalAnimation: animateNavBarItemToggle
  };
  navBarItemToggleSelector.click(function () {
    startAnimation(animationConfig);
  });
};

const initializeMenuNavToggle = function () {
  const navToggleSelector = $('.nav-toggle');
  const navBarSelector = $('.nav-bar');
  const animateNavBarToggle = function () {
    navToggleSelector.children('img').toggleClass('nav-toggle--closed');
    $('body').toggleClass('scroll-lock');
    $('.menu').toggleClass('menu--mobile-open');
  };
  const animationConfig = {
    element: navBarSelector,
    stateKey: 'navBarIsOpen',
    duration: 0,
    classNameAnimation: 'nav-bar--mobile-closed',
    classNameDisplay: 'nav-bar--mobile-hidden',
    generalAnimation: animateNavBarToggle
  };
  navToggleSelector.click(function () {
    startAnimation(animationConfig);
  });
};

const initializeStickyNavBar = function () {
  makeNavBarSticky();
  $(window).scroll(function () {
    makeNavBarSticky();
  });
};

const makeNavBarSticky = function () {
  const menuSelector = $('.menu');
  const stickyClass = 'menu--sticky';
  let height = $('.white-space').height();
  if ($(this).scrollTop() > height && !state.navBarIsSticky) {
    menuSelector.addClass(stickyClass);
    state.navBarIsSticky = true;
  } else if ($(this).scrollTop() < height && state.navBarIsSticky) {
    menuSelector.removeClass(stickyClass);
    state.navBarIsSticky = false;
  }
};
